class ReamazeApi {
  constructor(reamazeApiEndpoint) {
    this.reamazeApiEndpoint = reamazeApiEndpoint;
  }

  formatEndpoint(websiteId = '') {
    return this.reamazeApiEndpoint.replace(
      '{{websiteId}}',
      websiteId !== '' ? websiteId + '.' : websiteId
    );
  }

  updateBrandAvatar(websiteId, avatarUrl) {
    return window
      .fetch(`${this.formatEndpoint(websiteId)}/api/v2/brands/${websiteId}`, {
        method: 'PUT',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({ brand: { avatar_url: avatarUrl } }),
        credentials: 'include'
      })
      .then(res => {
        if (!res.ok) throw new Error('Brand avatar upload failed');
        return res.json();
      });
  }

  fetchBotsSettings(websiteId) {
    return window
      .fetch(`${this.formatEndpoint(websiteId)}/api/v2/bots`, {
        credentials: 'include'
      })
      .then(res => {
        if (!res.ok) throw new Error('Failed to fetch bot settings');
        return res.json();
      });
  }
}

export default ReamazeApi;
